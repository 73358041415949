import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import iga1 from "../../assets/img/iga1.png"
import iga2 from "../../assets/img/iga-yeni.png"

export default function IgaProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="IGA Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={iga1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("iga-airport")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("kulenin-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allahuekber")}
          </div>
          <img src={iga2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
